import React, { forwardRef } from 'react';
import { Link } from 'gatsby';

import { PositionLine, Title, LinkWrapper } from './Position.styled';

interface IPosition {
  title: string;
  link: string;
}

const Position = forwardRef<HTMLDivElement, IPosition>(({ title, link }, ref) => (
  <PositionLine ref={ref}>
    <Title>{title}</Title>
    <LinkWrapper>
      <Link to="/technology/">
        {link}
      </Link>
    </LinkWrapper>
  </PositionLine>
));

export default Position;
