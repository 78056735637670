import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MOBILE_TOP_MENU_HEIGHT } from 'constants/sizes';
import { Layout } from 'UI';

export const ScrollBar = styled.div`
  grid-row: 1;
  grid-column: 1/-1;
  height: 2px;
  position: relative;
  background: var(--white-opacity);

  ::after {
    position: absolute;
    content: '';
    display: block;
    height: 100%;
    top: 0;
    left: 0;
    width: var(--scroll-position);
    background: var(--mambo-green);
  }
`;

export const Container = styled(Layout)`
  position: absolute;
  width: 100%;
  height: 8em;
  padding-top: 3.5em;
  color: var(--white);
  row-gap: 1em;
  justify-items: space-between;
  align-content: start;
  z-index: 1;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: calc(${MOBILE_TOP_MENU_HEIGHT}px + 1.5em);
    row-gap: 0.7rem;
  }
`;
