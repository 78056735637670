import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import UseCase from '../UseCase/UseCase';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "Use case #2"}, page: {eq: "deep_reality_display"}) {
      title
      header
      text {
        title
        text
      }
      slide {
        id
        header
        title
        media {
          ...MediaImageFragment
        }
      }
    }
  }
`;

interface IADAS {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const ADAS: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IADAS) => data?.strapiWidgets && (
      <UseCase
        {...data.strapiWidgets}
        nextLink="truear"
        mobileShift={[0, 80, 0, 95]}
      />
    )}
  />
);

export default ADAS;
