import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';
import {
  GatsbySvg, MiddleText,
} from 'UI';

import { Content } from '../Slide/Slide.styled';

export const Header = styled.h3`
  grid-area: h;
  color: var(--mambo-green);
`;

export const Title = styled(MiddleText)`
  grid-area: t;
  white-space: pre-wrap;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 1em;
  }
`;

export const Logo = styled(GatsbySvg)`
  grid-area: l;

  > svg {
    width: 10em;
    height: auto;
  }
`;

export const Container = styled(Content)`
  will-change: transform;
  transition: transform 0.05s;
  position: relative;
  z-index: 1;
  color: var(--white);
  grid-template-rows: auto auto 1fr auto;

  grid-template-areas:
    'l l l l . . . . . .'
    'h h h h . . . . . .'
    't t t t . . . . . .';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    align-content: start;
    grid-template-rows: auto auto auto 1fr auto auto;
    grid-template-areas:
      'l l l l . .'
      'h h h h . .'
      't t t t . .';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    align-content: start;
    grid-template-rows: auto auto auto 20px auto auto;
    top: 0;
    /* padding-top: calc(${MOBILE_TOP_MENU_HEIGHT}px + 2em); */
    grid-template-areas:
      'l l l l'
      'h h h h'
      't t t t';
  }
`;
