import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import Slides from './Slides';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "how it works"}, page: {eq: "deep_reality_display"}) {
      title
      header
      id: strapiId
      text {
        title
        text
      }
      slide {
        id
        title
        header
        media {
          ...MediaImageFragment
        }
      }
    }
  }
`;

interface IHowItWorks {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const HowItWorks: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IHowItWorks) => data?.strapiWidgets && (
      <Slides {...data.strapiWidgets} />
    )}
  />
);

export default HowItWorks;
