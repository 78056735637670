import React, { FC } from 'react';

import { IMediaImage } from 'interfaces/media';
import { IComponentWidgetText } from 'interfaces/widgets';

import {
  Wrapper, Title, Text, Picture,
} from './SlideThree.styled';

interface ITextBlock {
  header: string;
  text: IComponentWidgetText;
  media: IMediaImage;
}

const SlideThree: FC<ITextBlock> = ({ header, text, media }) => (
  <Wrapper>
    <Picture {...media} />
    {header && <Title>{header}</Title>}
    {text?.text && <Text>{text?.text}</Text>}
  </Wrapper>
);

export default SlideThree;
