import styled from '@emotion/styled';

import { MiddleText } from 'UI';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(MiddleText)`
  margin-bottom: 0.34em;
  max-width: 12em;
`;

export const Text = styled.p``;

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 1em;
`;
