import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';

import {
  Wrapper, Title, TextOne, TextTwo, Text, SmallTitle, Container,
} from './SlideTwo.styled';

const SlideItem = forwardRef<HTMLDivElement, IComponentWidgetSlide<null>>(({
  header, text,
}, ref) => (
  <Container>
    <Wrapper ref={ref} withPaddings>
      <Title>{header}</Title>
      <TextOne>
        <SmallTitle>{text[0]?.title}</SmallTitle>
        <Text>{text[0]?.text}</Text>
      </TextOne>
      <TextTwo>
        <SmallTitle>{text[1]?.title}</SmallTitle>
        <Text>{text[1]?.text}</Text>
      </TextTwo>
    </Wrapper>
  </Container>
));

export default SlideItem;
