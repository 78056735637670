import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Layout, SectionTitle } from 'UI';

export const Container = styled(Layout)`
  margin: 4em 0 6em;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
    margin: 2em 0 3em;
  }
`;

export const Title = styled(SectionTitle)`
  grid-column: 1/3;

  span {
    display: block;
    position: sticky;
    top: 2em;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
    margin-bottom: 0;
  }
`;

export const AdvantagesList = styled.div`
  grid-column: 3/-1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
    grid-column-gap: 2rem;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    row-gap: 2em;
  }
`;
