import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import Slides from './Slides';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "partnerships"}, page: {eq: "deep_reality_display"}) {
      title
      header
      text {
        title
        text
      }
      slide {
        id
        header
        title
        text {
          id
          text
        }
        media {
          ...MediaImageFragment
        }
        file {
          ...MediaFileFragment
        }
      }
    }
  }
`;

interface IPartnerships {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const Partnerships: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IPartnerships) => data?.strapiWidgets && (
      <Slides {...data.strapiWidgets} />
    )}
  />
);

export default Partnerships;
