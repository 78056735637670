import React, { FC } from 'react';

import {
  Wrapper, Header, Title, Description,
} from './TextBlock.styled';

interface ITextBlock {
  title: string;
  description: string;
  header: string;
}

const TextBlock: FC<ITextBlock> = ({ header, title, description }) => (
  <Wrapper withPaddings>
    <Header>{header}</Header>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
);

export default TextBlock;
