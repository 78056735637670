import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';

export const Container = styled.div`
  position: relative;
  overflow: visible;
  pointer-events: none;
`;

export const MarksWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  overflow: visible;
`;

export const Dot = styled.div`
  position: absolute;
  top: -0.2em;
  left: -0.2em;
  width: 0.4em;
  height: 0.4em;
  background: var(--white);
  box-shadow: 0 0 0 0.3em rgba(255, 255, 255, 0.4);
  border-radius: 50%;

  ::before {
    position: absolute;
    content: '';
    left: -0.5em;
    top: -0.5em;
    width: 1em;
    height: 1em;
    pointer-events: auto;
  }
`;

export const Tooltip = styled.div`
  background: var(--mambo-green);
  color: var(--black);
  padding: 0.25em 1em;
  border-radius: 2em;
  position: absolute;
  top: 0;
  left: 1em;
  transform: translateY(-50%);
  display: none;
  white-space: pre;
  z-index: 1;
  text-align: center;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    &.mob-top {
      top: auto;
      bottom: 100%;
      transform: translate(calc(-50% - 1em), -1em);
    }

    &.mob-bottom {
      top: 100%;
      transform: translate(calc(-50% - 1em), 1em);
    }
  }
`;

export const Point = styled.div`
  position: absolute;
  pointer-events: auto;

  :hover {
    ${Dot} {
      background: var(--mambo-green);
    }

    ${Tooltip} {
      display: block;
    }
  }
`;
