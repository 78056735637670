import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';

export const Title = styled.p`
  grid-column: 1/6;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/4;
  }
`;

export const NextLink = styled.a`
  grid-column: 6/-1;
  justify-self: end;
  cursor: pointer;
  white-space: pre;

  ::after {
    content: ' →';
  }

  :hover {
    color: var(--red);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 4;
  }
`;
