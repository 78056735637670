import React, { forwardRef, Ref, FC } from 'react';

import { IMediaImage } from 'interfaces/media';

import {
  Picture, PictureWrapper, Marks, Lines, First, Second, Span, Container,
} from './Background.styled';

const marks = [
  {
    left: '-18%',
    top: '23%',
    content: 'Navigation line',
  },
  {
    left: '-5%',
    top: '17%',
    content: 'Entertaining content',
  },
  {
    left: '11%',
    top: '10%',
    content: 'ADAS',
  },
  {
    left: '27%',
    top: '2%',
    content: 'Entertaining content',
  },
];

interface IImage extends IMediaImage {
  className?: string;
}

export const Image: FC<IImage> = ({ className, ...media }) => (
  <Picture className={className} {...media}>
    <Lines>
      <First>
        <Span>
          True AR™ multiple depth planes
        </Span>
      </First>
      <Second>
        <Span>
          {'Virtual Instrument\nCluster'}
        </Span>
      </Second>
    </Lines>
    <Marks marks={marks} />
  </Picture>
);

export const BackgroundImage = forwardRef<HTMLDivElement, IMediaImage>(
  (media, ref) => (
    <PictureWrapper ref={ref}>
      <Image {...media} />
    </PictureWrapper>
  ),
);

interface IBackground {
  ref: Ref<HTMLDivElement>;
  pictureRef: Ref<HTMLDivElement>;
  media: IMediaImage;
}

const Background: FC<IBackground> = forwardRef<HTMLDivElement, IBackground>(({
  pictureRef, media,
}, ref) => (
  <Container ref={ref} gridOnly>
    <BackgroundImage ref={pictureRef} {...media} />
  </Container>
));

export default Background;
