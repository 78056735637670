import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import UseCase from '../UseCase/UseCase';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "Use case #1"}, page: {eq: "deep_reality_display"}) {
      title
      header
      text {
        title
        text
      }
      slide {
        id
        header
        title
        media {
          ...MediaImageFragment
        }
      }
    }
  }
`;

interface IGaming {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const Gaming: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IGaming) => data?.strapiWidgets && (
      <UseCase {...data.strapiWidgets} nextLink="adas" mobileShift={[0, 55.5, 0, 10]} />
    )}
  />
);

export default Gaming;
