import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Container, Title, Text, Picture, Header, Wrapper,
} from './Slide.styled';

interface ISlide extends IComponentWidgetSlide<IMediaImage> {
  className?: string;
  shift: number;
}

const Slide = forwardRef<HTMLDivElement, ISlide>(({
  title, header, media, className, shift,
}, ref) => (
  <Container ref={ref} className={className}>
    <Wrapper withPaddings>
      {title?.length ? (
        <>
          <Header>{title}</Header>
          <Text>{header}</Text>
        </>
      ) : (
        <Title>{header}</Title>
      )}
    </Wrapper>
    <Picture {...media} shift={shift} />
  </Container>
));

export default Slide;
