import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_4K } from 'constants/sizes';

import { ScrollableSlider } from 'UI';
import { Point } from 'UI/ImageMarks/ImageMarks.styled';
import {
  Wrapper as MenuWrapper,
  ScrollPointer,
  SliderMenuLink,
  Container as MenuContainer,
} from 'UI/ScrollableSlider/SliderMenu/styled';

import FirstSlide from './FirstSlide/FirstSlide';
import { Marks } from './FirstSlide/FirstSlide.styled';

export const ImageSlide = styled(FirstSlide)``;

export const Container = styled.div`
  &.is-hover_1 {
    ${Marks} ${Point} {
      :nth-of-type(2) {
        filter: none;
        transform: scale(2);
      }
    }

    ${SliderMenuLink} {
      :nth-of-type(2) { color: var(--mambo-green) }
    }
  }

  &.is-hover_2 {
    ${Marks} ${Point} {
      :nth-of-type(3) {
        filter: none;
        transform: scale(2);
      }
    }
    ${SliderMenuLink} {
      :nth-of-type(3) { color: var(--mambo-green) }
    }
  }

  &.is-hover_3 {
    ${Marks} ${Point} {
      :nth-of-type(4) {
        filter: none;
        transform: scale(2);
      }
    }
    ${SliderMenuLink} {
      :nth-of-type(4) { color: var(--mambo-green) }
    }
  }

  &.is-hover_4 {
    ${Marks} ${Point} {
      :nth-of-type(5) {
        filter: none;
        transform: scale(2);
      }
    }
    ${SliderMenuLink} {
      :nth-of-type(5) { color: var(--mambo-green) }
    }
  }
`;

export const Slider = styled(ScrollableSlider)`
  background: var(--black);

  ${ScrollPointer} {
    display: none;
  }

  ${SliderMenuLink} {
    pointer-events: auto;
    height: 1.4em;
    font-weight: 400;

    &.active {
      color: var(--mambo-green);
    }

    :nth-of-type(1) {
      display: none;
    }

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      justify-self: start;
    }
  }

  ${MenuContainer} {
    z-index: 2;
    pointer-events: none;
    margin-top: 3em;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      margin-top: 1em;
    }
  }

  ${MenuWrapper} {
    width: 100vw;
    column-gap: 1.5em;
    row-gap: 0;
    justify-content: start;
    position: relative;
    z-index: 2;
    font-size: 1.5em;
    grid-auto-flow: row;
    justify-items: start;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      grid-auto-flow: column;
      margin-top: 1em;
      font-size: 1em;
    }

    @media (min-width: ${MIN_4K}px) {
      margin-top: -0.7em;
    }
  }
`;
