import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage, IMediaSvg } from 'interfaces/media';
import { IPressRelease } from 'interfaces/press';

import Slides from './Slides';

const query = graphql`
  query {
    strapiPressReleases(
      slug: { eq: "IAA-2021" }
      isFuture: { eq: false }
    ) {
      slug
    }

    strapiWidgets(title: {eq: "iaa-mobility-2021"}, page: {eq: "deep_reality_display"}) {
      title
      header
      strapiId
      media {
        ...MediaSVGFragment
      }
      slide {
        header
        id
        title
        media {
          ...MediaImageFragment
          ...MediaSVGFragment
        }
        text {
          title
          text
        }
      }
    }
  }
`;

interface IAbout {
  strapiWidgets: IWidget<IMediaImage, IMediaImage | IMediaSvg>;
  strapiPressReleases?: IPressRelease;
}

const About: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IAbout) => data?.strapiWidgets && (
      <Slides
        slug={data?.strapiPressReleases?.slug}
        {...data.strapiWidgets}
      />
    )}
  />
);

export default About;
