import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';
import { GatsbyPicture } from 'UI';
import ImageMarks from 'UI/ImageMarks/ImageMarks';
import { Dot, Point } from 'UI/ImageMarks/ImageMarks.styled';

export const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 22em;
  overflow: hidden;
  clip-path: inset(0px 66% 0px 0px);

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    clip-path: none;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: -1;
  transition: transform 1s ease-in-out;

  ::before, ::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
  }

  ::before {
    top: 0;
    height: 5%;
    background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  }

  ::after {
    bottom: 0;
    height: 15%;
    background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    clip-path: none;
  }
`;

export const Marks = styled(ImageMarks)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  pointer-events: auto;

  ${Dot} {
    background: var(--mambo-green);
    box-shadow: none;
    width: 0.6rem;
    height: 0.6rem;
    top: -0.3rem;
    left: -0.3rem;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      width: 1em;
      height: 1em;
      top: -0.5em;
      left: -0.5em;
    }
  }

  ${Point} {
    transition: .2s ease-in-out;
  }

  ${Point} {
    cursor: pointer;
    filter: grayscale(1);

    :nth-of-type(1) { display: none }
    :hover {
      filter: grayscale(0);
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--white);
  pointer-events: none;


  @media (max-width: ${MAX_MOB_WIDTH}px) {
    z-index: -2;

    ::before {
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background: linear-gradient(to bottom, black 30%, transparent);
    }
  }

  &.active {
    ${Picture} {
      opacity: 1;
    }

    ${ImageWrapper} {
      top: 10em;
      clip-path: inset(0px 0% 0px 0px);
    }
  }

  &.is_0 ${Picture} {
    clip-path: inset(0px 0% 0px 0px);
  }

  &.is_1 ${Picture} {
    transform: scale(1.5) translate(-43%, -30%);
    ${Point}:nth-of-type(2) { filter: grayscale(0); }
  }

  &.is_2 ${Picture} {
    transform: scale(1.5) translate(-28%, -35%);
    ${Point}:nth-of-type(3) { filter: grayscale(0); }
  }

  &.is_3 ${Picture} {
    transform: scale(1.5) translate(-17%, -20%);
    ${Point}:nth-of-type(4) { filter: grayscale(0); }
  }

  &.is_4 ${Picture} {
    transform: scale(1.5) translate(-12%, -10%);
    ${Point}:nth-of-type(5) { filter: grayscale(0); }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    ${ImageWrapper} {
      transition: none;
    }

    &.is_1,
    &.is_2,
    &.is_3,
    &.is_4 {
      ${ImageWrapper} {
        z-index: 1;
        height: 60%;
        top: 0;
        z-index: -1;
      }

      ${Picture} {
        top: 70%;
        width: 400%;
      }
    }

    ${Picture} {
      clip-path: inset(0px 0% 0px 0px);
      width: 150%;
      transform: translate(-20%, -50%);
      transition: none;
    }

    &.is_1 ${Picture} {
      transform: translate(-60%, -49%);
    }

    &.is_2 ${Picture} {
      transform: translate(-44%, -56%);
    }

    &.is_3 ${Picture} {
      transform: translate(-33%, -36%);
    }

    &.is_4 ${Picture} {
      transform: translate(-28%, -25%);
    }
  }
`;
