import styled from '@emotion/styled';

export const MenuWrapper = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 3em;
`;

export const Item = styled.div`
  cursor: pointer;

  :hover {
    color: var(--mambo-green);
  }

  &.active {
    color: var(--mambo-green);
  }
`;
