import React, { FC } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Picture, Item, ItemTitle, ItemText, ItermWrapper,
} from './AdvantagesItem.styled';

const AdvantagesItem: FC<IComponentWidgetSlide<IMediaImage>> = ({
  id, title, header, media,
}) => (
  <Item>
    <ItermWrapper>
      <ItemTitle>{title}</ItemTitle>
      <ItemText>{header}</ItemText>
      <Picture key={id} {...media} />
    </ItermWrapper>
  </Item>
);

export default AdvantagesItem;
