import React, {
  useRef, createRef, FC, useEffect,
} from 'react';

import { IComponentWidgetSlide, IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { Container, Slider } from './UseCase.styled';

import TextBlock from './TextBlock/TextBlock';
import Slide from './Slide/Slide';
import Position from './Position/Position';

interface IUseCase extends IWidget<null, IMediaImage> {
  nextLink?: string;
  mobileShift?: number[];
}

const UseCase: FC<IUseCase> = ({
  slide, header, title, text, nextLink, mobileShift,
}) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();
  const positionRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  const slideRefs = useRef(slide.map(() => createRef<HTMLDivElement>()));

  let activeSlide = 0;
  const slidesList = slide.map(({ id }) => `${id}`);

  const setActiveClass = (item: number) => {
    const nodes = slideRefs.current;

    if (!nodes) return;

    nodes.forEach(({ current }, index) => (index === item ? current?.classList.add('active') : current?.classList.remove('active')));
  };

  const handleChangeSlide = (item: number = activeSlide) => {
    activeSlide = item;

    setActiveClass(item);
  };

  const handleVisibility = (visibility: boolean) => {
    if (visibility) handleChangeSlide(activeSlide);
  };

  const handleScroll = (percent: number) => {
    const position = positionRef.current;
    if (!position) return;

    const count = slidesList.length || 1;
    const countPercent = 100 / count;
    const absolutePosition = percent * countPercent + countPercent * activeSlide;

    position.style.setProperty('--scroll-position', `${absolutePosition}%`);
  };

  useEffect(() => {
    if (containerRef.current) setActiveClass(0);
  }, []);

  setActiveClass(0);

  const description = text?.find((item) => item.title === 'description')?.text || '';
  const next = text?.find((item) => item.title === 'next')?.text || '';

  return (
    <Container ref={containerRef}>
      <TextBlock
        header={title}
        title={header}
        description={description}
      />
      <Slider
        ref={sliderRef}
        items={slidesList}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
        onRelativeScroll={handleScroll}
        noMenu
      >
        <Position
          ref={positionRef}
          title={header}
          next={next}
          link={nextLink}
        />
        {slide.map((item: IComponentWidgetSlide<IMediaImage>, index) => (
          <Slide
            key={`slide_${item.id}`}
            ref={slideRefs.current[index]}
            shift={mobileShift?.[index] || 0}
            {...item}
          />
        ))}
      </Slider>
    </Container>
  );
};

export default UseCase;
