import React, { FC, ReactNode } from 'react';

import {
  Container, Point, MarksWrapper, Dot, Tooltip,
} from './ImageMarks.styled';

export interface IMark {
  left?: string;
  top?: string;
  right?: string;
  bottom?: string;
  content?: ReactNode;
  position?: 'right' | 'mob-bottom' | 'mob-top';
  onHover?: (index?: number) => void;
  onSelect?: (index?: number) => void;
}

export interface IImageMarks {
  marks: IMark[];
  className?: string;
  onHover?: (index?: number) => void;
  onSelect?: (index?: number) => void;
}

const ImageMarks: FC<IImageMarks> = ({
  className,
  marks = [],
  onHover = () => {},
  onSelect = () => {},
}) => (marks.length > 0) && (
  <Container className={className}>
    <MarksWrapper>
      {marks.map(({
        left, top, content, position,
      }, index) => (
        <Point
          key={`${left} ${top}`}
          style={{ left, top }}
          onMouseMove={() => onHover(index)}
          onMouseLeave={() => onHover(-1)}
          onClick={() => onSelect(index)}
        >
          <Dot />
          {content && (
            <Tooltip className={position}>
              {content}
            </Tooltip>
          )}
        </Point>
      ))}
    </MarksWrapper>
  </Container>
);

export default ImageMarks;
