import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Layout, MiddleText, SectionTitle } from 'UI';

export const Wrapper = styled(Layout)`
  padding-top: 6em;
  padding-bottom: 6em;
`;

export const Header = styled(SectionTitle)`
  grid-row: 1;
  grid-column: 1/-1;
  margin-bottom: 0;
`;

export const Title = styled.h3`
  grid-row: 2;
  grid-column: 1/7;
  white-space: pre-wrap;
  margin-bottom: 1em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Description = styled(MiddleText)`
  grid-row: 3;
  grid-column: 7/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;
