import styled from '@emotion/styled';

import { ScrollableSlider } from 'UI';

export const SlidesList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

export const Container = styled.div`
  position: relative;
  z-index: 1;
  background: #000;
  color: #fff;
`;

export const Slider = styled(ScrollableSlider)``;
