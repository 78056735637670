import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { Layout } from 'UI';

import { Title } from './Advantages.styled';
import AdvantagesItem from './AdvantagesItem/AdvantagesItem';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "advantages"}, page: {eq: "deep_reality_display"}) {
      title
      header
      slide {
        id
        title
        header
        media {
          ...MediaImageFragment
        }
      }
    }
  }
`;

interface IAdvantages {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const Advantages: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IAdvantages) => data?.strapiWidgets && (
      <Layout withPaddings>
        <Title>{data?.strapiWidgets?.header}</Title>
        {data?.strapiWidgets?.slide?.map((item) => (
          <AdvantagesItem key={item.id} {...item} />
        ))}
      </Layout>
    )}
  />
);

export default Advantages;
