import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';
import {
  GatsbyPicture, Layout, MiddleText, SectionTitle,
} from 'UI';
import ImageMarks from 'UI/ImageMarks/ImageMarks';

export const Wrapper = styled(Layout)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 1s;
  padding-top: 3.5em;
  pointer-events: none;
  align-content: start;
  z-index: 1;

  > * {
    pointer-events: none;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    top: auto;
    height: auto;
    top: 6em;
    padding-bottom: 1rem;
    min-height: 300px;
    row-gap: 10px;
  }
`;

export const Marks = styled(ImageMarks)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

export const ImageWrapper = styled.div`
  position: absolute;
  top: 22em;
  width: 66%;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: var(--black);
  z-index: -1;
  pointer-events: none;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    top: auto;
    height: 40%;
    bottom: 52px;
    width: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translateY(-50%) translateX(-50%);
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--white);
  pointer-events: none;

  &.active {
    ${Wrapper} {
      opacity: 1;

      > * {
        pointer-events: auto;
      }
    }

    ${ImageWrapper} {
      z-index: 0;
      opacity: 1;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    :nth-last-of-type(1) {
      ${Picture} {
        width: 200%;
        top: 25%;
      }
    }
  }


  :nth-of-type(2n) {
    @media (max-height: 680px) {
      ${Picture} {
        max-width: 80vh;
      }
    }
  }

  :nth-of-type(2n + 1) {
    @media (min-height: 680px) {
      ${Picture} {
        top: 0;
        transform: translate(-50%, 0);
      }
    }
  }
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 0;
  grid-column: 1/6;
  grid-row: 1;
  display: none;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: block;
  }
`;

export const Text = styled(MiddleText)`
  position: relative;
  grid-column: 6/-1;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }


  @media (max-width: ${MAX_TABLET_WIDTH}px) and (max-height: 640px) {
    font-size: 1.2em;
    line-height: 1.4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 2;
    font-size: 12px;
    grid-column: 1/-1;
    line-height: 1.5;
  }
`;
