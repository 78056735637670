import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';

import { SectionTitle, NextText } from 'UI';
import { ScrollBar } from 'UI/ScrollableSlider/PositionLine/PositionLine.styled';
import UIPositionLine from 'UI/ScrollableSlider/PositionLine/PositionLine';

export const Title = styled(SectionTitle)`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;

export const PositionLine = styled(UIPositionLine)`
  @media (min-width: ${MAX_MOB_WIDTH}px) {
    ${ScrollBar} {
      width: 2px;
      height: 7.5em;
      position: absolute;
      top: 2.8em;
      left: -1em;

      ::after {
        width: 100%;
        height: var(--scroll-position);
      }
    }
  }

  @media (min-width: ${MIN_2K}px) {
    ${ScrollBar} {
      height: 7em;
    }
  }

  @media (min-width: ${MIN_4K}px) {
    ${ScrollBar} {
      height: 6.5em;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    ${ScrollBar} {
      display: block;
    }
  }
`;

export const LinkWrapper = styled(NextText)`
  grid-column: 1/-1;
  color: var(--white);
  pointer-events: auto;
  margin-top: 8em;
  white-space: pre-wrap;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 0;
    grid-row: 1;
    grid-column: 4/-1;
    justify-self: end;
    display: none;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: none;
  }
`;
