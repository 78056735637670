import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH, MAX_MOB_WIDTH } from 'constants/sizes';
import { Layout, MiddleText } from 'UI';

import { Image } from '../Background/Background';
import { Marks, Lines } from '../Background/Background.styled';

export const Wrapper = styled(Layout)`
  row-gap: 2rem;
  padding-top: 10em;
  padding-bottom: 8em;
  margin-top: 25vh;
  position: relative;
  overflow: hidden;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 0;
    padding-top: 25vh;
  }
`;

export const Title = styled.h3`
  grid-column: 1/7;
  grid-row: 1;
  color: var(--mambo-green);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 2;
  }
`;

export const Text = styled(MiddleText)`
  grid-column: 1/7;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: 3;
  }
`;

export const Picture = styled(Image)`
  display: none;
  position: static;
  grid-row: 1;
  grid-column: 1/-1;
  margin: -20px;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    display: block;
  }

  ${Marks} {
    opacity: 1;
    filter: blur(0);
    transition: 0.4s ease-in-out;
  }

  ${Lines} {
    opacity: 1;
    filter: blur(0);
    transition: 0.4s ease-in-out;
  }
`;
