import React, {
  useRef, FC, useEffect,
} from 'react';

import { IComponentWidgetSlide, IWidget } from 'interfaces/widgets';
import { IMediaImage, IMediaSvg } from 'interfaces/media';
import { MAX_MOB_WIDTH } from 'constants/sizes';
import throttle from 'utils/throttle';

import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo';
import SlideThree from './SlideThree/SlideThree';

import { Container } from './About.styled';
import Background from './Background/Background';

interface IAboutSlider extends IWidget<IMediaSvg, IMediaImage | IMediaSvg> {
  slug: string;
}

const AboutSlides: FC<IAboutSlider> = ({
  slide, header, media, slug,
}) => {
  const pictureWrapperRef = useRef<HTMLDivElement>();
  const pictureRef = useRef<HTMLDivElement>();
  const secondSliderRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  /*   const slideRefs = useRef(slide.map(() => createRef<HTMLDivElement>()));
  */

  const scaleDesktop = (percent: number) => {
    const picture = pictureRef.current;
    const second = secondSliderRef.current;
    const isMobile = window.innerWidth < MAX_MOB_WIDTH;

    if (!picture || isMobile || !second) return;

    const multiplier = 1 - percent;

    if (percent < 1) {
      const scale = 1.0 + 0.6 * multiplier;
      const translateX = multiplier * 2;
      const translateY = multiplier * 35;
      picture.style.transform = `scale(${scale})
      translateX(${translateX}%) translateY(${translateY}%)`;
      pictureWrapperRef.current.style.position = 'fixed';
      pictureWrapperRef.current.style.top = '0';
      pictureWrapperRef.current.style.left = null;
      second.style.opacity = null;
    } else if (percent < 1.4) {
      picture.style.transform = 'scale(1) translateX(0%) translateY(0%)';
      pictureWrapperRef.current.style.position = 'fixed';
      pictureWrapperRef.current.style.top = '0';
      pictureWrapperRef.current.style.left = null;
    } else {
      pictureWrapperRef.current.style.position = 'absolute';
      pictureWrapperRef.current.style.top = '140vh';
      pictureWrapperRef.current.style.left = '0';
    }

    if (percent >= 1 && percent < 1.2) {
      second.style.opacity = `${(1.2 - percent) * 5}`;
    } else if (percent >= 1.2) {
      second.style.opacity = '0';
    }

    if (percent >= 1.2) {
      picture.classList.add('lines');
    } else {
      picture.classList.remove('lines');
    }
  };

  const scaleMobile = (percent: number) => {
    const picture = pictureRef.current;
    const multiplier = 0.25 - percent;

    if (!picture) return;

    if (percent < 0.25) {
      const scale = 1.0 + 10 * multiplier;
      picture.style.transform = `scale(${scale}) translateX(${-60 * multiplier}%)`;
    } else {
      picture.style.transform = 'scale(1) translateX(0%)';
    }
  };

  const handleScale = () => {
    const percent = window.scrollY / window.innerHeight;

    if (window.innerWidth >= MAX_MOB_WIDTH) {
      scaleDesktop(percent);
    } else {
      scaleMobile(percent);
    }
  };

  const handleScroll = () => {
    throttle(() => handleScale(), 30)();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    handleScale();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container>
      <SlideOne
        headerText={header}
        logo={media[0]}
        slug={slug}
        {...slide[0]}
      />
      <SlideTwo
        ref={secondSliderRef}
        {...slide[1] as IComponentWidgetSlide<null>}
      />
      <SlideThree
        header={slide?.[2]?.header}
        text={slide?.[2]?.text?.[0]}
        media={slide[0]?.media}
      />
      <Background
        ref={pictureWrapperRef}
        pictureRef={pictureRef}
        media={slide[0]?.media}
      />
    </Container>
  );
};

export default AboutSlides;
