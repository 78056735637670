import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH, MIN_2K, MAX_TABLET_WIDTH, STANDART_DESKTOP_WIDTH,
} from 'constants/sizes';
import {
  GatsbyPicture, Layout,
} from 'UI';
import ImageMarks from 'UI/ImageMarks/ImageMarks';
import { Dot, Tooltip, Point } from 'UI/ImageMarks/ImageMarks.styled';

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 15%;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, black);
  }
`;

export const Marks = styled(ImageMarks)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: visible;
  z-index: 1;
  opacity: 0;
  filter: blur(30px);

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    bottom: 0;
    top: auto;
  }

  ${Dot} {
    display: none;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      display: block;
    }
  }

  ${Tooltip} {
    opacity: 0.32;
    background: none;
    display: block;
    white-space: nowrap;
    color: var(--white);
    padding: 0;
    text-align: left;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      opacity: 0;
      top: 2em;
      left: -0.5em;
      white-space: normal;
      max-width: 8em;
    }
  }

  ${Point} {
    @media (max-width: ${MAX_MOB_WIDTH}px) {
      :hover {
        z-index: 1;

        ${Tooltip} {
          opacity: 0.32;
        }
      }
    }
  }
`;

export const Lines = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  filter: blur(30px);
`;

const Line = styled.div`
  position: absolute;
  border-top: 1px solid;
  border-left: 1px solid;
  border-color: var(--white-opacity);
`;

export const First = styled(Line)`
  width: 59%;
  height: 48%;
  left: -15%;
  top: -52%;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 30%;
    border-right: 1px solid;;
    border-color: var(--white-opacity);
  }
`;

export const Second = styled(Line)`
  width: 0;
  height: 67%;
  left: -31%;
  top: -52%;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    left: -41%;
    top: -70%;
    width: 8%;
    height: 89%;
    border-top: none;
    border-bottom: 1px solid var(--white-opacity);
  }
`;

export const Span = styled.span`
  position: absolute;
  top: -3em;
  left: 0;
  white-space: pre;
`;

export const PictureWrapper = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  right: 0;
  z-index: -2;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  transform: scale(1.6) translateX(2%) translateY(35%);

  &.lines {
    ${Marks} {
      opacity: 1;
      filter: blur(0);
      transition: 0.4s ease-in-out;
    }

    ${Lines} {
      opacity: 1;
      filter: blur(0);
      transition: 0.4s ease-in-out;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    top: 0;
    transform: scale(3.5) translateX(-15%);
  }
`;

export const Container = styled(Layout)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  margin-top: 5%;
  overflow: hidden;
  z-index: -1;
  display: block;
  width: auto;

  left: 1670px;

  @media (min-width: ${MIN_2K}px) {
    left: 6.55%;
  }

  @media (min-width: ${STANDART_DESKTOP_WIDTH}px) {
    left: 14%;
  }

  @media (min-width: ${MIN_2K}px) {
    left: 10.9%;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    left: 170px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    left: 0px;
    position: absolute;
    top: 60vh;
    margin-top: 0;
  }
`;
