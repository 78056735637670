import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';
import { Layout } from 'UI';

const contentAppear = keyframes`
  from  { visibility: hidden; }
  to    { visibility: visible; }
`;

const textIn = keyframes`
  from  { opacity: 0; }
  20%   { opacity: 0; }
  to    { opacity: 1; }
`;

export const Content = styled(Layout)`
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 50px;
    align-content: flex-end;
    grid-template-rows: auto;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 175px;
    padding-bottom: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 320px;
    padding-bottom: 240px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: calc(${MOBILE_TOP_MENU_HEIGHT}px + 2rem);
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-delay: 0.6s;
  will-change: visibility;
  z-index: -1;
  pointer-events: none;

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;

    background: #000;
  }

  :not(.active) {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 1s;
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }

  &.active {
    animation: ${contentAppear} 0s ease-out;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    z-index: -1;

    ${Content} {
      animation: ${textIn} 1s ease-in-out;
      opacity: 1;
    }
  }
`;
