import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Container, Text, Picture, Header, Wrapper, Logo,
} from './Slide.styled';

interface ISlide extends IComponentWidgetSlide<IMediaImage> {
  className?: string;
  first?: boolean;
}

const Slide = forwardRef<HTMLDivElement, ISlide>(({
  text, header, media, className, file, first,
}, ref) => (
  <Container ref={ref} className={className}>
    <Wrapper withPaddings>
      {file && (
        <Logo src={file?.localFile?.publicURL} className={first ? 'first' : ''} />
      )}
      <Header>{header}</Header>
      {text?.map((item) => (
        <Text key={item.id}>{item.text}</Text>
      ))}
    </Wrapper>
    <Picture {...media} />
  </Container>
));

export default Slide;
