import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import {
  GatsbyPicture,
  Picture as UIPicture,
  Layout,
  MiddleText,
} from 'UI';

export const Wrapper = styled(Layout)`
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 1s;
  margin-top: 5rem;
  grid-template-rows: auto 1fr auto;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 2.4rem;
    padding-bottom: 6em;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: .2s;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    ::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, black, transparent);
    }
  }
`;

interface ILogo {
  first?: boolean;
}

export const Logo = styled(UIPicture)<ILogo>`
  grid-row: 1;
  grid-column: 1/5;
  margin-top: 1em;

  > img {
    width: auto;
    max-width: 12em;
    max-height: 4em;
  }

  &.first {
    > img {
      max-width: 6.5em;
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    max-height: 3em;
    margin-bottom: 1em;

    > img {
      max-width: 120px;
    }

    &.first {
      > img {
        max-width: 70px;
      }
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--white);

  &.active {
    ${Wrapper} {
      opacity: 1;
    }

    ${Picture} {
      opacity: 1;
    }
  }
`;

export const Header = styled(MiddleText)`
  grid-column: 1/5;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Text = styled.p`
  grid-column: span 2;
  grid-row: 3;
  align-self: start;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-row: auto;
    grid-column: 1/-1;
  }
`;
