import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Container, Picture, ImageWrapper, Marks,
} from './FirstSlide.styled';
import { zero } from '../dots';

interface IFirstSlide extends IComponentWidgetSlide<IMediaImage> {
  className?: string;
  pointHover: (index: number) => void;
  onSelect: (index: number) => void;
}

const FirstSlide = forwardRef<HTMLDivElement, IFirstSlide>(({
  media, className, pointHover, onSelect,
}, ref) => (
  <Container
    className={className}
    ref={ref}
  >
    <ImageWrapper>
      <Picture {...media}>
        <Marks marks={zero} onHover={pointHover} onSelect={onSelect} />
      </Picture>
    </ImageWrapper>
  </Container>
));

export default FirstSlide;
