import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';
import { GatsbyPicture, Layout, MiddleText } from 'UI';

export const Wrapper = styled(Layout)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 1s;
  margin-top: 5rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    align-content: start;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 0;
    height: 60%;
    align-content: end;
    padding-bottom: 1.5em;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: .2s;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    top: auto;
    height: 40%;
    bottom: 0;
    width: 100%;
  }
`;

export const Header = styled.h3`
  grid-column: 1/5;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Title = styled(MiddleText)`
  grid-column: 1/5;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Text = styled(MiddleText)`
  grid-column: 7/-1;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
    grid-row: 2;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--white);

  &.active {
    ${Wrapper} {
      opacity: 1;
    }

    ${Picture} {
      opacity: 1;
    }
  }
`;
