import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';
import { IMark } from 'UI/ImageMarks/ImageMarks';

import {
  Container, Text, Picture, Wrapper, Marks, ImageWrapper, Title,
} from './Slide.styled';

interface ISlide extends IComponentWidgetSlide<IMediaImage> {
  className?: string;
  marks?: IMark[];
  sectionTitle: string;
}

const Slide = forwardRef<HTMLDivElement, ISlide>(({
  header, media, className, marks, sectionTitle,
}, ref) => (
  <Container ref={ref} className={className}>
    <Wrapper withPaddings>
      <Title>{sectionTitle}</Title>
      <Text>{header}</Text>
    </Wrapper>
    <ImageWrapper>
      <Picture {...media}>
        {marks && <Marks marks={marks} />}
      </Picture>
    </ImageWrapper>
  </Container>
));

export default Slide;
