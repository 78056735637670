import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveBlock } from 'store/actions';

import PositionLine from 'UI/ScrollableSlider/PositionLine/PositionLine';

import { NextLink, Title } from './Position.styled';

interface IPosition {
  title: string;
  next?: string;
  link?: string;
}

const Position = forwardRef<HTMLDivElement, IPosition>(({
  title, next, link,
}, ref) => {
  const dispatch = useDispatch();

  const setCurrBlock = () => {
    const activeElement = document.querySelector(`[data-id="${link}"]`);

    setTimeout(() => {
      activeElement?.scrollIntoView({ behavior: 'smooth' });
    }, 0);

    dispatch(setActiveBlock(`#${link}`));
  };

  return (
    <PositionLine ref={ref}>
      <Title>{title}</Title>
      {next && <NextLink onClick={setCurrBlock}>{next}</NextLink>}
    </PositionLine>
  );
});

export default Position;
