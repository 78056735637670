import React, { FC } from 'react';

import {
  Wrapper, Header, Title, Description,
} from './TextBlock.styled';

interface ITextBlock {
  title: string;
  description: string;
  header: string;
}

const TextBlock: FC<ITextBlock> = ({ header, title, description }) => (
  <Wrapper withPaddings>
    {header.length > 0 && <Header>{header}</Header>}
    {title.length > 0 && <Title>{title}</Title>}
    {description.length > 0 && <Description>{description}</Description>}
  </Wrapper>
);

export default TextBlock;
