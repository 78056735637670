import React, { forwardRef, ReactNode } from 'react';

import { Container, ScrollBar } from './PositionLine.styled';

interface IPositionLine {
  children?: ReactNode;
  className?: string;
}

const PositionLine = forwardRef<HTMLDivElement, IPositionLine>(({ children, className }, ref) => (
  <Container ref={ref} className={className}>
    <ScrollBar />
    {children}
  </Container>
));

export default PositionLine;
