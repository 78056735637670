import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { Layout, MiddleText } from 'UI';

import { Content } from '../Slide/Slide.styled';

export const Title = styled(MiddleText)`
  grid-area: h;
  align-self: start;
  white-space: pre-line;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Text = styled.p``;

export const Container = styled(Layout)`
  height: 100vh;
  position: relative;
`;

export const Wrapper = styled(Content)`
  position: absolute;
  color: var(--white);
  align-content: space-between;
  align-items: end;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'h h h h h h . . . .'
    '. . . . . . t t q q';

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    align-content: space-between;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'h h h h . .'
      '. . t t q q';
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    align-content: end;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'h h h h'
      't t t t'
      'q q q q';
  }
`;

export const TextWrapper = styled.div`
  align-self: start;
`;

export const SmallTitle = styled.p`
  color: var(--red);
`;

export const TextOne = styled(TextWrapper)`
  grid-area: t;
`;

export const TextTwo = styled(TextWrapper)`
  grid-area: q;
`;
