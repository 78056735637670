import React, {
  createRef, FC, MutableRefObject, useEffect, useRef,
} from 'react';

import { IComponentWidgetSlide, IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';
import { IMark } from 'UI/ImageMarks/ImageMarks';

import { Container, Slider, ImageSlide } from './HowItWorks.styled';

import Slide from './Slide/Slide';
import Position from './Position/Position';
import { laserUnitDors, generationUnitDots, hoeDots } from './dots';

const marks: IMark[][] = [null, laserUnitDors, null, generationUnitDots, hoeDots];

interface ISliderRef extends HTMLDivElement {
  setMenu?: (index: number) => void;
  setNewSlide?: (index: number, smooth?: boolean) => void;
}

const HowItWorks: FC<IWidget<null, IMediaImage>> = ({
  slide, header, text,
}) => {
  const sliderRef = useRef<ISliderRef>(null);
  const containerRef = useRef<HTMLDivElement>();
  const positionRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  const slideRefs = useRef<MutableRefObject<HTMLDivElement>[]>(
    slide.map(() => createRef<HTMLDivElement>()),
  );
  const menuRefs = useRef<MutableRefObject<HTMLDivElement>[]>(
    slide.map(() => createRef<HTMLDivElement>()),
  );

  let visible = false;
  let activeSlide = 0;
  const slidesList = slide.map((item) => item.title || '');

  const pointHover = (index: number) => {
    const container = containerRef.current;

    if (!container) return;

    if (activeSlide > 0 || !container.classList?.contains(`is-hover_${index}`)) {
      // eslint-disable-next-line no-param-reassign
      container.className = container.className.replace(/is-hover_[\d]*/ig, '');
      if (index < 0) return;
      container.classList.add(`is-hover_${index}`);
    }
  };

  const moveImage = (index: number) => {
    const imageSlide = slideRefs.current[0].current;

    if (imageSlide && !imageSlide?.classList?.contains(`is_${index}`)) {
      // eslint-disable-next-line no-param-reassign
      imageSlide.className = imageSlide.className.replace(/is_[\d]*/ig, '');
      imageSlide?.classList.add(`is_${index}`);
    }
  };

  const setActiveClass = (item: number) => {
    const nodes = slideRefs.current;
    const menus = menuRefs.current;

    if (!nodes) return;

    nodes.forEach(({ current }, index) => {
      const menuItem = menus[index].current;

      if (index === item) {
        menuItem?.classList.add('active');
        current?.classList.add('active');
      } else {
        menuItem?.classList.remove('active');
        current?.classList.remove('active');
      }
    });

    moveImage(item);
  };

  const handleChangeSlide = (item: number = activeSlide) => {
    activeSlide = item;

    pointHover(-1);
    setActiveClass(item);
    // sliderRef.current?.setNewSlide(item);
  };

  const handleVisibility = (visibility: boolean) => {
    if (visibility && visibility !== visible) {
      const container = containerRef.current;
      const { top } = container.getBoundingClientRect();

      if (top > -200) {
        setTimeout(() => {
          sliderRef.current?.setNewSlide(0);
        }, 10);
      } else {
        handleChangeSlide(activeSlide);
      }
    }
    visible = visibility;
  };

  useEffect(() => {
    if (containerRef.current) setActiveClass(0);
  }, []);

  setActiveClass(0);

  const handleScroll = (percent: number) => {
    const position = positionRef.current;
    if (!position) return;

    const count = slidesList.length || 1;
    const countPercent = 100 / count;
    const absolutePosition = percent * countPercent + countPercent * activeSlide;

    position.style.setProperty('--scroll-position', `${absolutePosition}%`);
  };

  const linkText = text.find((item) => item.title === 'link')?.text || '';

  return (
    <Container ref={containerRef}>
      <Slider
        ref={sliderRef}
        items={slidesList}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
        onRelativeScroll={handleScroll}
        onMenuItemHover={pointHover}
      >
        <Position
          ref={positionRef}
          title={header}
          link={linkText}
        />
        <ImageSlide
          ref={slideRefs.current[0]}
          pointHover={pointHover}
          onSelect={(item) => sliderRef.current?.setNewSlide(item)}
          {...slide[0]}
        />
        {slide.map((item: IComponentWidgetSlide<IMediaImage>, index) => (index !== 0 && (
          <Slide
            key={`slide_${item.id}`}
            ref={slideRefs.current[index]}
            marks={marks[index]}
            sectionTitle={header}
            {...item}
          />
        )))}
      </Slider>
    </Container>
  );
};

export default HowItWorks;
