import { IMark } from 'UI/ImageMarks/ImageMarks';

export const zero: IMark[] = [
  {
    left: '',
    top: '',
  },
  {
    left: '22%',
    top: '0%',
  },
  {
    left: '6%',
    top: '3.5%',
  },
  {
    left: '-4.2%',
    top: '-14.5%',
  },
  {
    left: '-9%',
    top: '-23.5%',
  },
];

export const laserUnitDors: IMark[] = [
  {
    left: '-36%',
    top: '-20%',
    content: 'Printed Circuit Board (PCB)',
  },
  {
    left: '-28%',
    top: '4%',
    content: 'Lasers',
  },
  {
    left: '-25%',
    top: '14%',
    content: 'Dichronic and\nreflecting mirrors',
  },
  {
    left: '-33%',
    top: '22%',
    content: 'Collimator',
  },
  {
    left: '-13%',
    top: '22%',
    content: 'Fiber connector',
  },
  {
    left: '-7%',
    top: '12%',
    content: 'Housing',
  },
  {
    left: '-3%',
    top: '-39%',
    content: 'Cover',
  },
  {
    left: '-2%',
    top: '-26%',
    content: 'Board',
  },
  {
    left: '10%',
    top: '-2%',
    content: 'Radiator',
  },
  {
    left: '27%',
    top: '32%',
    content: 'Cooling system',
    position: 'mob-top',
  },
];

export const generationUnitDots: IMark[] = [
  {
    left: '-21%',
    top: '-27%',
    content: 'Holographic corrector\n(WayRay custom design\nthird party production)',
    position: 'mob-bottom',
  },
  {
    left: '-1%',
    top: '17%',
    content: 'Diffuser screen\n(WayRay custom design)',
    position: 'mob-top',
  },
  {
    left: '16%',
    top: '34%',
    content: 'Projector corrector\n(WayRay custom design)',
    position: 'mob-top',
  },
  {
    left: '16%',
    top: '-38%',
    content: 'Housing',
  },
  {
    left: '1%',
    top: '-17%',
    content: 'DLP Projector',
    position: 'mob-bottom',
  },
  {
    left: '-1%',
    top: '-5%',
    content: 'Fiber coupling',
  },
];

export const hoeDots: IMark[] = [
  {
    left: '-2%',
    top: '-11%',
    content: 'Outer glass',
  },
  {
    left: '-2%',
    top: '-4%',
    content: 'Outer adhesive layer e.g. PVB',
  },
  {
    left: '-2%',
    top: '0.5%',
    content: 'Special function layer',
  },
  {
    left: '-2%',
    top: '14%',
    content: 'Holographic Optical Element',
  },
  {
    left: '-2%',
    top: '22%',
    content: 'Special function layer',
  },
  {
    left: '-2%',
    top: '28%',
    content: 'Inner adhesive layer e.g. PVB',
  },
  {
    left: '-2%',
    top: '35%',
    content: 'Inner glass',
  },
];
