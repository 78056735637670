import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import {
  GatsbyPicture, MiddleText,
} from 'UI';

export const ItemTitle = styled(MiddleText)`
  max-width: 20em;
  width: 100%;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 80%;
  }
`;

export const ItemText = styled.p`
  align-self: end;
  max-width: 20em;
  width: 100%;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 80%;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  height: 0;
  width: 100%;
  padding-bottom: 66.6%;
  display: block;
  z-index: -1;

  img {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
  }
`;

export const ItermWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
  color: var(--white);

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding: 1rem;
  }
`;

export const Item = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 66.6%;
  z-index: 1;

  grid-column: span 5;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: span 3;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;
