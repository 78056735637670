import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { IPressRelease } from 'interfaces/press';
import { IQueryPage } from 'interfaces/page';

import Head from 'components/Layout/Head/Head';

import { setActiveBlock, setPreviousBlock } from 'store/actions';
import { Intersection } from 'UI';

import About from 'components/blocks/DeepRealityDisplay/About/About';
import Gaming from 'components/blocks/DeepRealityDisplay/Gaming/Gaming';
import TrueAR from 'components/blocks/DeepRealityDisplay/TrueAR/TrueAR';
import ADAS from 'components/blocks/DeepRealityDisplay/ADAS/ADAS';
import Advantages from 'components/blocks/DeepRealityDisplay/Advantages/Advantages';
import Additionally from 'components/blocks/DeepRealityDisplay/Additionally/Additionally';
import HowItWorks from 'components/blocks/DeepRealityDisplay/HowItWorks/HowItWorks';
import Partnerships from 'components/blocks/DeepRealityDisplay/Partnerships/Partnerships';

const DeepRealityDisplayPage: FC = () => {
  const dispatch = useDispatch();

  const setBlock = ({ title, visibility }: ISetBlock) => {
    if (visibility) {
      dispatch(setActiveBlock(`#${title}`));
    } else {
      dispatch(setPreviousBlock(`#${title}`));
    }
  };

  return (
    <>
      <Intersection id="about" way={setBlock}>
        <About />
      </Intersection>

      <Intersection id="gaming" way={setBlock}>
        <Gaming />
      </Intersection>

      <Intersection id="adas" way={setBlock}>
        <ADAS />
      </Intersection>

      <Intersection id="truear" way={setBlock}>
        <TrueAR />
      </Intersection>

      <Intersection id="advantages" way={setBlock}>
        <Advantages />
        <Additionally />
      </Intersection>

      <Intersection id="how_it_works" way={setBlock}>
        <HowItWorks />
      </Intersection>

      <Intersection id="partnerships" way={setBlock}>
        <Partnerships />
      </Intersection>
    </>
  );
};

const query = graphql`
  query {
    strapiPressReleases(
      slug: { eq: "IAA-2021" }
      isFuture: { eq: false }
    ) {
      slug
    }

    page: strapiDeepRealityDisplayPage {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

interface IDRDQueryPage extends IQueryPage {
  strapiPressReleases: IPressRelease;
}

const DeepRealityDisplayPageQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IDRDQueryPage) => {
      const siteInfo = data.page.meta;
      const { strapiPressReleases } = data;

      if (!strapiPressReleases) return null;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
            slug="deep-reality-display"
          />
          <DeepRealityDisplayPage />
        </>
      );
    }}
  />
);

export default DeepRealityDisplayPageQuery;
