import React, { forwardRef, Ref } from 'react';

import PositionLine from 'UI/ScrollableSlider/PositionLine/PositionLine';

import { Item, MenuWrapper } from './Position.styled';

interface IMenuItem {
  title: string;
  index: number;
  onClick: (index: number) => void;
}

const MenuItem = forwardRef<HTMLDivElement, IMenuItem>(({ title, onClick, index }, ref) => (
  <Item ref={ref} onClick={() => onClick(index - 1)}>
    {title}
  </Item>
));

interface IPosition {
  menu: string[];
  setSlide: (index: number) => void;
  menuRefs: Ref<HTMLDivElement>[];
}

const Position = forwardRef<HTMLDivElement, IPosition>(({
  menu, setSlide, menuRefs,
}, ref) => (
  <PositionLine ref={ref}>
    <MenuWrapper>
      {menu.map((item, index) => (
        <MenuItem
          key={item}
          onClick={setSlide}
          title={item}
          index={index}
          ref={menuRefs[index]}
        />
      ))}
    </MenuWrapper>
  </PositionLine>
));

export default Position;
