import React, { FC } from 'react';

import { IMediaImage, IMediaSvg } from 'interfaces/media';
import { IComponentWidgetSlide } from 'interfaces/widgets';

import {
  Title, Header, Logo, Container,
} from './SlideOne.styled';

interface ISlideItem extends IComponentWidgetSlide<IMediaImage> {
  logo: IMediaSvg;
  headerText: string;
}

const SlideItem: FC<ISlideItem> = ({
  header, headerText, logo,
}) => (
  <Container withPaddings>
    <Logo media={logo} />
    <Header>{headerText}</Header>
    <Title>{header}</Title>
  </Container>
);

export default SlideItem;
